.ilustrationIcon {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.content {
  align-self: stretch;
  background-color: #f9fafb;
  height: 666px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 38px 61px;
  box-sizing: border-box;
}
.here {
  color: #263238;
}
a[href^="mailto:"]
{ 
  font-family: sans-serif;
  color: red;
  font-size: 11px;
}

.span {
  color: #ff9c00;
}
.sameplaceioAllRightsContainer {
  position: relative;
}
.bottom,
.homepage {
  display: flex;
  justify-content: flex-start;
}
.bottom {
  align-self: stretch;
  background-color: #f9fafb;
  flex-direction: row;
  align-items: center;
  padding: 20px 68px 50px 50px;
}
.homepage {
  position: relative;
  width: 100%;
  height: 856px;
  flex-direction: column;
  align-items: flex-end;
  max-height: 4200px;
  text-align: left;
  font-size: 12px;
  color: #344756;
  font-family: Roboto;
}
@media screen and (max-width: 1200px) {
  .content {
    flex-direction: row;
  }
}
@media screen and (max-width: 834px) {
  .content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 360px) {
  .ilustrationIcon {
    display: flex;
  }
}
