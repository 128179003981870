.video {
  align-self: stretch;
  flex: 1;
  position: relative;
  cursor: pointer;
}
.videopopup {
  width: 720px;
  height: 720px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}
