.vectorIcon {
  position: absolute;
  height: 84.67%;
  width: 84.67%;
  top: 7.33%;
  right: 8%;
  bottom: 8%;
  left: 7.33%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.basicsX {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 15px;
  height: 15px;
}
.essentialsShieldTick {
  position: relative;
  width: 40px;
  height: 40px;
}
.icon,
.topIcons {
  align-self: stretch;
}
.icon {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.topIcons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.termsAndConditions {
  margin: 0;
  align-self: stretch;
  position: relative;
  line-height: 117%;
  font-weight: 800;
}
.introduction1 {
  line-height: 150%;
}
.introduction {
  display: block;
}
.bySubscribingToOurMailing {
  margin-bottom: 0;
}
.theseTermsOfServiceTosO {
  margin: 0;
  padding-left: 17px;
}
.tossameplaceio {
  color: #344756;
}
.span,
.tossameplaceio {
  font-family: Roboto;
}
.youHaveThe {
  line-height: 174.5%;
}
.orUseThe,
.tossameplaceio2 {
  line-height: 150%;
  font-family: Roboto;
  color: #344756;
}
.orUseThe {
  line-height: 174.5%;
  color: #1d2939;
}
.introduction11TheseContainer {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
}
.here {
  color: #263238;
}
.span2 {
  color: #ff9c00;
}
.sameplaceioAllRightsContainer {
  align-self: stretch;
  position: relative;
  font-size: 12px;
  color: #344756;
}
.termsandconditions,
.termsandconditionspopup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.termsandconditionspopup {
  align-self: stretch;
  flex: 1;
  gap: 25px;
}
.termsandconditions {
  border-radius: 24px;
  background-color: #fff;
  width: 825px;
  height: 1628px;
  padding: 41px 100px 89px;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: left;
  font-size: 34px;
  color: #1d2939;
  font-family: Roboto;
}
@media screen and (max-width: 1200px) {
  .termsandconditions {
    padding-top: 50px;
    padding-bottom: 60px;
    box-sizing: border-box;
    cursor: auto;
  }
}
@media screen and (max-width: 834px) {
  .termsandconditions {
    padding: 30px 40px 50px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 360px) {
  .termsAndConditions {
    font-weight: 600;
    font-family: Roboto;
    font-size: 25px;
  }
  .introduction11TheseContainer {
    font-size: 10px;
  }
  .termsandconditions {
    padding: 20px;
    box-sizing: border-box;
  }
}
