.improveLivesOf,
.welcomeToThe {
  position: relative;
  display: inline-block;
  width: 488px;
}
.improveLivesOf {
  cursor: pointer;
  width: 100%;
  font-size: 41px;
  font-weight: 700;
  color: #000;
  min-width: 200px;
  max-width: 464px;
}
.vectorIcon {
  position: relative;
  width: 16px;
  height: 18px;
}
.buttonText {
  position: relative;
  font-size: 16px;
  letter-spacing: 0.03em;
  line-height: 150%;
  font-family: Roboto;
  color: #fff;
  text-align: center;
}
.vectorParent {
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0.5px;
  width: 131px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  box-sizing: border-box;
  gap: 7px;
  z-index: 0;
}
.iconRight {
  position: relative;
  width: 32px;
  height: 32px;
  display: none;
  z-index: 1;
}
.buttonlearnmore,
.content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  width: 131px;
  height: 32px;
  flex-direction: row;
  position: relative;
}
.buttonlearnmore {
  cursor: pointer;
  border: 0;
  padding: 8px 10px;
  background-color: #344756;
  border-radius: 24px;
  width: 253px;
  height: 48px;
  flex-direction: column;
  box-sizing: border-box;
}
.buttonlearnmore:hover {
  background-color: rgba(52, 72, 87, 0.8);
}
.buttonText1 {
  position: relative;
  font-size: 16px;
  letter-spacing: 0.03em;
  line-height: 150%;
  font-family: Roboto;
  color: #1d2939;
  text-align: center;
}
.buttonTextWrapper {
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 188px;
  height: 32px;
  flex-direction: row;
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 0;
}
.buttonTextWrapper,
.buttontac,
.content1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1 {
  width: 188px;
  height: 32px;
  flex-direction: row;
  position: relative;
}
.buttontac {
  cursor: pointer;
  border: 1px solid #000;
  padding: 8px 10px;
  background-color: #f9f9f9;
  border-radius: 24px;
  box-sizing: border-box;
  width: 253px;
  height: 48px;
  flex-direction: column;
}
.buttontac:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.buttonlearnmoreParent,
.contentleft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}
.contentleft {
  gap: 50px;
  min-width: 200px;
  max-width: 488px;
  z-index: 2;
  text-align: left;
  font-size: 15px;
  color: #344756;
  font-family: Roboto;
}
@media screen and (max-width: 1200px) {
  .contentleft {
    max-width: 488px;
  }
}
@media screen and (max-width: 834px) {
  .improveLivesOf {
    font-size: 28px;
    min-width: 300px;
  }
  .contentleft {
    gap: 30px;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 0;
    box-sizing: border-box;
    min-width: 280px;
    max-width: 80px;
  }
}
@media screen and (max-width: 360px) {
  .improveLivesOf {
    font-size: 24px;
  }
  .buttonlearnmore,
  .buttontac {
    border-radius: 20px;
  }
  .contentleft {
    gap: 30px;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 20px;
    box-sizing: border-box;
    min-width: 0;
    max-width: 280px;
  }
}
