.base {
  position: absolute;
  height: 66.67%;
  width: 66.67%;
  top: 16.67%;
  right: 16.67%;
  bottom: 16.67%;
  left: 16.67%;
  border-radius: 6px;
  background-color: #ccc;
  display: none;
}
.base1,
.base2,
.base3,
.base4 {
  position: absolute;
  height: 25%;
  width: 25%;
  top: 20.83%;
  right: 54.17%;
  bottom: 54.17%;
  left: 20.83%;
  border-radius: 0 2px 2px 2px;
  background-color: #d2d1d4;
}
.base2,
.base3,
.base4 {
  top: 54.17%;
  bottom: 20.83%;
  border-radius: 2px 2px 2px 0;
}
.base3,
.base4 {
  top: 20.83%;
  right: 20.83%;
  bottom: 54.17%;
  left: 54.17%;
  border-radius: 2px 0 2px 2px;
}
.base4 {
  top: 54.17%;
  bottom: 20.83%;
  border-radius: 2px 2px 0 2px;
}
.iconPlaceholder {
  width: 24px;
  height: 24px;
  overflow: hidden;
}
