.logoIcon {
  position: relative;
  width: 33px;
  height: 38px;
}
.buttonText {
  position: relative;
  font-size: 16px;
  letter-spacing: 0.03em;
  line-height: 150%;
  font-family: Roboto;
  color: #1d2939;
  text-align: center;
}
.buttonTextWrapper {
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: -0.5px;
  width: 172px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 0;
}
.iconRight {
  position: relative;
  width: 32px;
  height: 32px;
  display: none;
  z-index: 1;
}
.buttonstartjourney,
.content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  width: 172px;
  height: 32px;
  flex-direction: row;
  position: relative;
}
.buttonstartjourney {
  cursor: pointer;
  border: 1px solid #000;
  padding: 8px 10px;
  background-color: #f9f9f9;
  border-radius: 24px;
  box-sizing: border-box;
  width: 199px;
  height: 48px;
  flex-direction: column;
}
.buttonstartjourney:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.navigation {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 50px;
}
@media screen and (max-width: 360px) {
  .buttonstartjourney {
    display: flex;
    border-radius: 20px;
  }
  .navigation {
    padding: 10px 20px;
    box-sizing: border-box;
  }
}
