.base {
  position: absolute;
  height: 66.5%;
  width: 66.5%;
  top: 16.5%;
  right: 17%;
  bottom: 17%;
  left: 16.5%;
  border-radius: 6px;
  background-color: #ccc;
  display: none;
}
.base1,
.base2,
.base3,
.base4 {
  position: absolute;
  height: 25%;
  width: 25%;
  top: 21%;
  right: 54%;
  bottom: 54%;
  left: 21%;
  border-radius: 0 2px 2px 2px;
  background-color: #d2d1d4;
}
.base2,
.base3,
.base4 {
  top: 54%;
  bottom: 21%;
  border-radius: 2px 2px 2px 0;
}
.base3,
.base4 {
  top: 21%;
  right: 21%;
  bottom: 54%;
  left: 54%;
  border-radius: 2px 0 2px 2px;
}
.base4 {
  top: 54%;
  bottom: 21%;
  border-radius: 2px 2px 0 2px;
}
.iconPlaceholder {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.iconLeft {
  position: relative;
  width: 32px;
  height: 32px;
  display: none;
}
.buttonText {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 112%;
}
.text {
  padding: 8px;
}
.content,
.text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.buttonMedium,
.sizemediumStatedefaultTy {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.buttonMedium {
  border-radius: 24px;
  border: 1px solid #e4e7ec;
  box-sizing: border-box;
  height: 48px;
  align-items: center;
  padding: 8px 12px;
}
.sizemediumStatedefaultTy {
  align-items: flex-start;
  text-align: center;
  font-size: 14px;
  color: #1a232b;
  font-family: Roboto;
}
